import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { closeComponentsModal, createComponentAction } from '../store/package-designer.actions';
import {
  COMPONENT_TYPES_DESTINATIONS,
  COMPONENT_TYPES_SOURCES,
  COMPONENT_TYPES_TRANSFORMATIONS,
  COMPONENT_TYPES_WORKFLOWS,
  ComponentTypeItem,
} from '../../constants/component_types';
import { selectComponentsModalFlags } from '../store/package-designer.selectors';
import { COMPONENT_TYPE } from '../../constants/component_type';
import { MatDialog } from '@angular/material/dialog';
import { AiTransformationsDialogComponent } from '../../common/services/components/ai-transformations-dialog.component';
import { selectPDFToCSVComponentFeatureFlag } from '../../account/store/account.selectors';
import { map } from 'rxjs/operators';

@Component({
  selector: 'components-list',
  template: `
    <div class="components-list" *ngIf="componentsModalFlags$ | async as componentsModalFlags">
      <div class="components-list-sources" *ngIf="componentsModalFlags.hasSources">
        <div class="title-container">
          <div class="components-list-item-icon-background"></div>
          <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
            <use class="components-list-item-icon" width="32" height="32" href="#icon-sources"></use>
          </svg>
          <strong class="lead">{{ 'components-list.labels.sources' | translate }}</strong>
        </div>
        <ul class="list-group">
          <li
            class="list-group-item"
            *ngFor="let source of sources"
            (click)="addComponentFromList(source)"
            [id]="source.componentType"
          >
            <div class="designer-components-list-icon-background"></div>
            <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
              <use [attr.href]="'#' + source.icon" class="designer-component-icon"></use>
            </svg>
            <span class="item-name">{{ source.originalName }}</span>
          </li>
        </ul>
      </div>
      <div class="components-list-transformations" *ngIf="componentsModalFlags.hasTransformations">
        <div class="title-container">
          <div class="components-list-item-icon-background"></div>
          <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
            <use class="components-list-item-icon" width="32" height="32" href="#icon-transformations"></use>
          </svg>
          <strong class="lead">{{ 'components-list.labels.transformations' | translate }}</strong>
        </div>
        <ul class="list-group">
          <li
            class="list-group-item"
            *ngFor="let transformation of transformations"
            (click)="addComponentFromList(transformation)"
            [id]="transformation.componentType"
          >
            <div class="designer-components-list-icon-background"></div>
            <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
              <use [attr.href]="'#' + transformation.icon" class="designer-component-icon"></use>
            </svg>
            <span class="item-name">{{ transformation.originalName }}</span>
          </li>
        </ul>
      </div>
      <div class="components-list-destinations" *ngIf="componentsModalFlags.hasDestinations">
        <div class="title-container">
          <div class="components-list-item-icon-background"></div>
          <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
            <use class="components-list-item-icon" width="32" height="32" href="#icon-destinations"></use>
          </svg>
          <strong class="lead">{{ 'components-list.labels.destinations' | translate }}</strong>
        </div>
        <ul class="list-group">
          <li
            class="list-group-item"
            *ngFor="let destination of destinations"
            (click)="addComponentFromList(destination)"
            [id]="destination.componentType"
          >
            <div class="designer-components-list-icon-background"></div>
            <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
              <use [attr.href]="'#' + destination.icon" class="designer-component-icon"></use>
            </svg>
            <span class="item-name">{{ destination.originalName }}</span>
            <span *ngIf="destination.newBadge" class="badge badge-secondary">New</span>
          </li>
        </ul>
      </div>
      <div class="components-list-workflows" *ngIf="componentsModalFlags.hasWorkflow">
        <strong class="lead">{{ 'components-list.labels.task' | translate }}</strong>
        <ul class="list-group">
          <li
            class="list-group-item"
            *ngFor="let workflow of workflows$ | async"
            (click)="addComponentFromList(workflow)"
            [id]="workflow.componentType"
          >
            <div class="designer-components-list-icon-background"></div>
            <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
              <use [attr.href]="'#' + workflow.icon" class="designer-component-icon" [ngClass]="workflow.icon"></use>
            </svg>
            <span class="item-name">{{ workflow.originalName }}</span>
          </li>
        </ul>
      </div>
    </div>
  `,
})
export class ComponentsListComponent {
  componentsModalFlags$ = this.store.select(selectComponentsModalFlags);
  pdfToCSVComponentFeatureFlag = this.store.select(selectPDFToCSVComponentFeatureFlag);

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
  ) {}

  sources = COMPONENT_TYPES_SOURCES;
  transformations = COMPONENT_TYPES_TRANSFORMATIONS;
  destinations = COMPONENT_TYPES_DESTINATIONS;
  workflows$ = this.pdfToCSVComponentFeatureFlag.pipe(
    map((pdfToCSVComponentFeatureFlag) =>
      pdfToCSVComponentFeatureFlag
        ? COMPONENT_TYPES_WORKFLOWS
        : COMPONENT_TYPES_WORKFLOWS.filter(
            (workflow) => workflow.componentType !== COMPONENT_TYPE.PDF_TO_CSV_COMPONENT,
          ),
    ),
  );

  addComponentFromList(component: ComponentTypeItem) {
    if (component.componentType === COMPONENT_TYPE.AI_TRANSFORMATIONS) {
      this.showPopup();
    } else {
      this.store.dispatch(closeComponentsModal());
      this.store.dispatch(createComponentAction({ component }));
    }
  }

  showPopup() {
    return this.dialog.open(AiTransformationsDialogComponent, {
      position: {
        top: '150px',
      },
      maxWidth: '1000px',
      minWidth: '',
    });
  }

  closeModal() {
    this.store.dispatch(closeComponentsModal());
  }
}
