import { ConnectionTypeName, Owner } from '../connections/connection.models';
import { ConditionOperator } from '../constants/conditions_operator_picker';
import { SchemaRequestData } from './helpers/schema.helpers';
import { DesignerSchemaFieldI } from './models/designer-schema-field.model';
import {
  AUTHENTICATION_TYPES,
  REST_API_SOURCE_METHODS,
  REST_API_REQUEST_TYPES,
  REST_API_RESPONSE_TYPES,
  REST_API_DESTINATION_METHODS,
  REST_API_METHODS,
  NUMBER_OF_REQUESTS,
} from '../constants/component_types';
import { has } from 'lodash';

export type XYCoords = [number, number];

export enum COMPONENT_PREVIEWER_STATUSES {
  creating = 'creating',
  failed = 'failed',
  stopped = 'stopped',
  pending = 'pending',
  running = 'running',
  provisioning = 'provisioning',
  completed = 'completed',
  validating = 'validating',
}

interface ComponentPreviewLogError {
  message: string;
  field?: string;
  component_id?: string;
}

export interface ComponentPreviewLogMessage {
  id: number;
  status: COMPONENT_PREVIEWER_STATUSES;
  status_message: string;
  account_id: number;
  owner_id: number;
  runtime?: string;
  errors: Array<ComponentPreviewLogError | string>;
  created_at: string;
  updated_at: string;
  progress: number;
  package_id: number;
  owner: Owner;
  type: string;
}

export interface ResultDataField {
  [key: string]: string;
}

export interface ComponentPreviewResultData {
  data?: any;
  fields?: ResultDataField[];
  raw?: string[];
  error?: string;
  info?: string;
}

export interface PackagePreviewPayload {
  data_flow_json: {
    components: Component[];
    edges: Edge[];
  };
  destination_schema: Schema;
  variables: Variables;
  source_schema_importer_parameters: Partial<SchemaRequestData>[];
  package_id: number;
}

export interface Variables {
  [key: string]: string;
}

export interface VariableItem {
  key: string;
  value: string;
  default: string;
  type?: string;
  error?: string;
}

export interface Field {
  name: string;
  projected_name?: string;
  alias?: string;
  data_type?: string;
  type?: string;
  order_type?: string;
  column_name?: string;
  pig_data_type?: string;
  request?: {
    action_attribution_windows?: string;
    fields: string;
  };
  response?: string;
}

export interface ExpressionField {
  left: string;
  right: string;
  operator: ConditionOperator;
}

export interface UnionField {
  left: string;
  right: string;
  alias: string;
}

export interface WindowedField {
  alias: string;
  default_value: string;
  function_name: string;
  field_name: string;
  ntiles: string;
  offset: string;
  range_after: string;
  range_before: string;
}

export interface GroupedField {
  field_name: string;
}

export interface AggregatedField {
  field_name: string;
  alias: string;
  function_name: string;
  projected_field_name: string;
}

export interface Relation {
  fields: string[];
}

export interface PredicateExpression {
  left: string;
  operator: ConditionOperator;
  right: string;
}

export interface Predicate {
  match_type: string;
  expressions: PredicateExpression[];
}

export interface ColumnMap {
  column_name: string;
  required?: boolean;
  field_name: string;
  is_merge_key?: boolean;
  field_type?: string;
  is_reference_key?: boolean;
  relationship_field_name?: string;
  relationship_field_type?: string;
  relationship_name?: string;
  objectField?: DesignerSchemaFieldI;
  is_bson?: boolean;
  is_bag?: boolean;
  FC_pristine?: boolean;
  isFocused?: boolean;
  isDuplicateError?: boolean;
  id?: string;
  relationKeys?: {
    is_reference_key: boolean;
    name: string;
    type: string;
  }[];
}

export interface Connection {
  id: number;
  name: string;
  type: ConnectionTypeName;
  unique_id?: string;
  updated_at?: string;
}

export interface Schema {
  valid?: boolean;
  fields: Array<Field & ConnectionSchemaField>;
  name?: string;
  id?: string;
}

export interface NoteComponentData extends CommonComponentData {
  description: string;
  noteHeight?: number;
  noteWidth?: number;
}
export interface NoteComponent {
  sticky_note_component: DatabaseDestinationComponentData;
}

export interface DatabaseDestinationComponentData extends CommonComponentData {
  add_columns: boolean;
  batch_size: number;
  column_mappings: ColumnMap[];
  create_table: boolean;
  database_connection_id: string;
  operation_type: string;
  parallel: number;
  schema: Schema;
  schema_name: string;
  single_transaction_per_batch: boolean;
  split_by_field: string;
  table_name: string;
  warehouse?: string;
  database?: string;
  pre_action_sql?: string;
  post_action_sql?: string;
  identity_insert_sql_server?: boolean;
  comp_update?: string;
  explicit_ids?: boolean;
  round_decimals?: boolean;
  replacement_char?: string;
  intermediate_compression_type?: string;
  max_errors?: number;
  max_bad_records?: number;
  truncate_columns?: boolean;
  trim_blanks?: boolean;
  empty_as_null?: boolean;
  blank_as_null?: boolean;
  null_string?: string;
  comp_rows?: number;
}
export interface DatabaseDestinationComponent {
  database_destination_component: DatabaseDestinationComponentData;
}

export interface DatabaseSourceComponentData extends CommonComponentData {
  access_mode: 'query' | 'table' | 'object';
  before_action: string;
  database_connection_id: string;
  parallel: number;
  query: string;
  schema: Schema;
  schema_name: string;
  split_by_column_name: string;
  table_name: string;
  where_clause: string;
}

export interface CSVDestinationType {
  escape_character: string;
  field_delimiter: string;
  line_ending?: string;
  text_qualifier: string;
  write_header?: boolean;
  skip_header?: boolean;
}

export enum RECORD_TYPE {
  XML = 'xml',
  DELIMITED = 'delimited',
  JSON = 'json',
  EXCEL = 'excel',
  RAW = 'raw',
  PARQUET = 'parquet',
}

export enum RECORD_DELIMITER {
  NEW_LINE = 'new_line',
  EOF = 'eof',
}

export interface CloudStorageSourceComponentData extends CommonComponentData {
  bucket: string;
  char_encoding: string;
  record_settings: CSVDestinationType;
  json_path: string;
  xml_path?: string;
  path: string;
  record_delimiter: RECORD_DELIMITER;
  record_type: RECORD_TYPE;
  source_path_field_alias: string;
  schema: Schema;
  before_action: string;
  manifest_path?: string;
  sheet_name?: string;
  ingest_large_excel_file?: boolean;
  zip_password?: string;
  manifest_connection?: Partial<Connection>;
  destination_type: {
    csv_destination_type?: CSVDestinationType;
    json_destination_type?: {
      json_destination_type: boolean;
    };
    parquet_destination_type?: {
      parquet_destination_type: boolean;
    };
  };
}
export interface CloudStorageSourceComponent {
  cloud_storage_source_component: CloudStorageSourceComponentData;
}

export interface CloudStorageDestinationComponentData extends CommonComponentData {
  destination_type: {
    csv_destination_type?: CSVDestinationType;
    json_destination_type?: {
      json_destination_type: boolean;
    };
    parquet_destination_type?: {
      parquet_destination_type: boolean;
    };
  };
  file_names: 'default' | 'custom';
  prefix: string;
  suffix: string;
  delete_destination_path?: boolean;
  destination_exists_action?: string;
  compression_type: string;
  enforce_single_file: boolean;
  record_delimiter: RECORD_DELIMITER;
  record_type: RECORD_TYPE;
  source_path_field_alias?: string;
  schema?: Schema;
  before_action?: string;
  manifest_connection?: Partial<Connection>;
  bucket: string;
  operation_type: string;
  path: string;
  char_encoding: string;
}
export interface CloudStorageDestinationComponent {
  cloud_storage_source_component: CloudStorageDestinationComponentData;
}

export interface FacebookAdsInsightsSourceComponentData extends CommonComponentData {
  api_version: string;
  level: string;
  action_report_time: string;
  time_increment: string;
  report_date_range_type: string;
  filtering: string;
  report_on: string;
  ad_accounts: string[];
  report_date_range_min?: string;
  report_date_range_max?: string;
  schema: Schema;
  manual_breakdown?: 'DayByDay' | 'Default';
  with_custom_fields?: boolean;
}
export interface FacebookAdsInsightsSourceComponent {
  facebook_ads_insights_source_component: FacebookAdsInsightsSourceComponentData;
}

export interface FacebookDestinationComponentData extends CommonComponentData {
  account_id: string;
  object_name: string;
  operation_type: string;
  batch_size: number;
  max_errors: number;
  column_mappings: ColumnMap[];
}
export interface FacebookDestinationComponent {
  facebook_destination_component: FacebookDestinationComponentData;
}

export interface HubspotDestinationComponentData extends CommonComponentData {
  account_id: string;
  object_name: string;
  operation_type: string;
  batch_size: number;
  max_errors: number;
  column_mappings: ColumnMap[];
  associations?: any[];
  track_errors?: boolean;
  errors_output_path?: string;
  errors_output_connection?: Connection;
  id_property?: string;
}
export interface HubspotDestinationComponent {
  hubspot_destination_component: HubspotDestinationComponentData;
}

export interface TikTokDestinationComponentData extends CommonComponentData {
  account_id: string;
  object_name: string;
  operation_type: string;
  batch_size: number;
  max_errors: number;
  column_mappings: ColumnMap[];
}
export interface TikTokDestinationComponent {
  facebook_destination_component: TikTokDestinationComponentData;
}

export interface AnalyticsSourceComponentData extends CommonComponentData {
  report_on: string;
  profile_ids: string;
  report_date_range_min: string;
  report_date_range_max: string;
  metrics: string[];
  dimensions: string[];
  segment: string;
  sort: string;
  filters: string;
  sampling_level: string;
  include_empty?: boolean;
  schema: Schema;
  profile_id_field_alias: string;
  account_name_field_alias: string;
  property_name_field_alias: string;
  profile_name_field_alias: string;
  report_sampling_level: string;
  include_empty_rows: boolean;
  api_version?: string;
  report_date_range_type?: string;
  quota_user: number;
}
export interface AnalyticsSourceComponent {
  analytics_source_component: AnalyticsSourceComponentData;
}

export interface Analytics4SourceComponentData extends CommonComponentData {
  api_version: string;
  report_on: string;
  property_ids: string;
  report_date_range_min: string;
  report_date_range_max: string;
  metrics: string[];
  dimensions: string[];
  include_empty?: boolean;
  schema: Schema;
  property_id_field_alias: string;
  account_name_field_alias: string;
  property_name_field_alias: string;
  profile_name_field_alias?: string;
  profile_id_field_alias?: string;
  account_id_field_alias: string;
  report_sampling_level: string;
  include_empty_rows: boolean;
  custom_fields: boolean;
  quota_user: number;
}
export interface Analytics4SourceComponent {
  analytics_ga4_source_component: Analytics4SourceComponentData;
}

export interface BingAdsSourceComponentData extends CommonComponentData {
  time_period_hour_field_alias: string;
  schema: Schema;
  time_period_date_field_alias: string;
  report_type: string;
  accounts_campaigns?: string;
  report_aggregation_type: string;
  report_date_range_type: string;
  hasTimePeriodField?: boolean;
  api_version?: string;
  report_on: string;
  columns?: any[];
  time_zone: string;
}
export interface BingAdsSourceComponent {
  analytics_source_component: BingAdsSourceComponentData;
}

export interface BigQuerySourceComponentData extends DatabaseSourceComponentData {
  use_legacy_sql: boolean;
  stored_procedure: boolean;
}
export interface BigQuerySourceComponent {
  big_query_source_component: BigQuerySourceComponentData;
}

export interface AdwordsSourceComponentData extends CommonComponentData {
  api_version: string;
  report_type: string;
  performance_max_reporting_option?: boolean;
  report_date_range_field?: string;
  report_date_range_type: string;
  report_date_range_min: string;
  report_date_range_max: string;
  manual_breakdown: string;
  customer_id_field_alias: string;
  customer_ids: string;
  report_on: string;
  schema: Schema;
  ad_accounts: string[];
}
export interface AdwordsSourceComponent {
  adwords_source_component: AdwordsSourceComponentData;
}

export interface SelectComponentData extends CommonComponentData {
  fields: Field[];
}
export interface SelectComponent {
  select_component: SelectComponentData;
}

export interface SortComponentData extends CommonComponentData {
  fields: Field[];
}
export interface SortComponent {
  sort_component: SortComponentData;
}

export interface RankComponentData extends CommonComponentData {
  dense: boolean;
  field_alias: string;
  fields: Field[];
  data_order: 'no_sort' | 'sort';
}
export interface RankComponent {
  rank_component: RankComponentData;
}

export interface LimitComponentData extends CommonComponentData {
  n: number;
  partitioned_fields: Field[];
  ordered_fields: Field[];
  partitioning: 'partition_by' | 'all';
  order: 'no_sort' | 'sort';
}
export interface LimitComponent {
  limit_component: LimitComponentData;
}

export interface WindowComponentData extends CommonComponentData {
  partitioned_fields: Field[];
  ordered_fields: Field[];
  windowed_fields: WindowedField[];
  grouping_type: string;
  data_order: string;
}
export interface WindowComponent {
  window_component: WindowComponentData;
}

export interface SampleComponentData extends CommonComponentData {
  percentage: number;
}
export interface SampleComponent {
  sample_component: SampleComponentData;
}

export interface JoinComponentData extends CommonComponentData {
  join_type: string;
  optimization_type: string;
  relations: Relation[];
}
export interface JoinComponent {
  join_component: JoinComponentData;
}

export interface CrossJoinComponentData extends CommonComponentData {}
export interface CrossJoinComponent {
  cross_join_component: CrossJoinComponentData;
}

export interface CloneComponentData extends CommonComponentData {}
export interface CloneComponent {
  clone_component: CloneComponentData;
}

export interface UnionComponentData extends CommonComponentData {
  fields: UnionField[];
}
export interface UnionComponent {
  union_component: UnionComponentData;
}

export interface FilterComponentData extends CommonComponentData {
  predicates: Predicate;
}
export interface FilterComponent {
  filter_component: FilterComponentData;
}

export interface AssertComponentData extends CommonComponentData {
  predicates: Predicate;
  message: string;
  data_order?: string;
}
export interface AssertComponent {
  assert_component: AssertComponentData;
}

export interface AggregateComponentData extends CommonComponentData {
  grouped_fields: GroupedField[];
  aggregated_fields: AggregatedField[];
  grouping_type: string;
}
export interface AggregateComponent {
  aggregate_component: AggregateComponentData;
}

export interface CubeComponentData extends CommonComponentData {
  grouped_fields: GroupedField[];
  aggregated_fields: AggregatedField[];
}
export interface CubeComponent {
  cube_component: CubeComponentData;
}

export interface DistinctComponentData extends CommonComponentData {}
export interface DistinctComponent {
  distinct_component: DistinctComponentData;
}

export interface RestAPIHeader {
  name: string;
  value: string;
}

export interface RestApiSourceComponentData extends CommonComponentData {
  authentication?: AUTHENTICATION_TYPES;
  body: string;
  headers: RestAPIHeader[];
  url: string;
  json_path: string;
  schema: Schema;
  rest_api_connection_id: string;
  method: REST_API_METHODS;
  username: string;
  password: string;
  response_type: REST_API_RESPONSE_TYPES;
  use_pagination: boolean;
  pagination_scheme: string;
  pagination_sleep_interval: string;
  max_requests: string;
}
export interface RestApiSourceComponent {
  rest_api_source_component: RestApiSourceComponentData;
}

export interface RestApiDestinationComponentData extends CommonComponentData {
  authentication?: AUTHENTICATION_TYPES;
  headers: RestAPIHeader[];
  url: string;
  json_path: string;
  schema: Schema;
  rest_api_connection_id: string;
  method: REST_API_METHODS;
  username: string;
  password: string;
  request_type: REST_API_REQUEST_TYPES;
  batch_size: number;
  number_of_requests: NUMBER_OF_REQUESTS;
  max_requests: string;
  fields: Field[];
  save_responses_to_file: boolean;
  responses_output_connection?: Connection;
  responses_output_path?: string;
  fail_on_error_response: boolean;
  column_mappings: ColumnMap[];
  json_consts: ColumnMap[];
  send_records_as_nested_attribute: boolean;
  nested_attribute_name: string;
  is_custom_payload?: boolean;
  custom_payload: string;
  set_field_name: boolean;
}
export interface RestApiDestinationComponent {
  rest_api_destination_component: RestApiDestinationComponentData;
}

export interface SalesforceSourceComponentData extends CommonComponentData {
  object_name: string;
  where_clause: string;
  schema: Schema;
  query: string;
  access_mode: 'query' | 'object' | 'table';
  is_chunk_size_enabled: boolean;
  chunk_size: number;
  source_action: string;
  operation_type?: string;
  id_field?: string;
  api_version?: string;
  max_records?: number;
}
export interface SalesforceSourceComponent {
  salesforce_source_component: SalesforceSourceComponentData;
}

export interface SalesforceDestinationComponentData extends CommonComponentData {
  operation_type: string;
  id_field: string;
  batch_size: number;
  max_errors: number;
  errors_output_connection?: Connection;
  track_errors?: boolean;
  enable_null_value?: boolean;
  errors_output_path?: string;
  column_mappings: ColumnMap[];
  object_name: string;
  parallel?: number;
  api_version?: string;
}
export interface SalesforceDestinationComponent {
  salesforce_destination_component: SalesforceDestinationComponentData;
}

export interface AmazonRedshiftSourceComponentData extends DatabaseSourceComponentData {
  null_string: string;
}
export interface AmazonRedshiftSourceComponent {
  amazon_redshift_source_component: AmazonRedshiftSourceComponentData;
}

export interface AmazonRedshiftDestinationComponentData extends DatabaseDestinationComponentData {
  null_string: string;
}
export interface AmazonRedshiftDestinationComponent {
  amazon_redshift_destination_component: AmazonRedshiftDestinationComponentData;
}

export interface MongoSourceComponentData extends DatabaseSourceComponentData {}
export interface MongoSourceComponent {
  mongo_source_component: MongoSourceComponentData;
}

export interface MongoDestinationComponentData extends DatabaseDestinationComponentData {}
export interface MongoDestinationComponent {
  mongo_destination_component: MongoDestinationComponentData;
}

export interface SpannerSourceComponentData extends DatabaseSourceComponentData {}
export interface SpannerSourceComponent {
  spanner_source_component: SpannerSourceComponentData;
}

export interface SnowflakeDestinationComponentData extends DatabaseDestinationComponentData {
  warehouse_name: string;
  database_name: string;
}
export interface SnowflakeDestinationComponent {
  snowflake_destination_component: SnowflakeDestinationComponentData;
}

export interface BigQueryDestinationComponentData extends DatabaseDestinationComponentData {}
export interface BigQueryDestinationComponent {
  bigquery_destination_component: BigQueryDestinationComponentData;
}

export interface GoogleSpannerDestinationComponentData extends DatabaseDestinationComponentData {}
export interface GoogleSpannerDestinationComponent {
  google_spanner_destination_component: GoogleSpannerDestinationComponentData;
}

export interface NetsuiteSourceComponentData extends DatabaseSourceComponentData {}
export interface NetsuiteSourceComponent {
  netsuite_source_component: NetsuiteSourceComponentData;
}

export interface NetsuiteSOAPDestinationComponentData extends DatabaseDestinationComponentData {
  object_name: string;
  id_field: string;
}
export interface NetsuiteSOAPDestinationComponent {
  netsuite_soap_destination_component: NetsuiteSOAPDestinationComponentData;
}

export interface GoogleAdsDestinationComponentData extends DatabaseDestinationComponentData {
  customer_id: string;
  login_customer_id: string;
  object_name: string;
}
export interface GoogleAdsDestinationComponent {
  google_ads_destination_component: GoogleAdsDestinationComponentData;
}

export interface ExecuteSQLComponentData extends CommonComponentData {
  operator: 'and' | 'or' | 'all';
  result_variable: string;
  result_type: string;
  query: string;
}
export interface ExecuteSQLComponent {
  execute_sql_component: ExecuteSQLComponentData;
}

export interface FileMoverComponentData extends CommonComponentData {
  operator: 'and' | 'or' | 'all';
  source_connection?: Partial<Connection>;
  destination_connection?: Partial<Connection>;
  source_bucket: string;
  source_path: string;
  destination_bucket: string;
  destination_path: string;
  delete_files_from_source: boolean;
  fail_on_empty_source: boolean;
  compression_type: string;
}
export interface FileMoverComponent {
  file_mover_component: FileMoverComponentData;
}

export interface PdfToCsvComponentData extends CommonComponentData {
  operator: 'and' | 'or' | 'all';
  source_connection?: Partial<Connection>;
  destination_connection?: Partial<Connection>;
  source_bucket: string;
  source_path: string;
  destination_bucket: string;
  destination_path: string;
}
export interface PdfToCsvComponent {
  pdf_to_csv_component: PdfToCsvComponentData;
}

export interface RunPackageComponentData extends CommonComponentData {
  package_id: number;
  package_name: string;
  variables: Variables;
  secret_variables: Variables;
  operator: 'or' | 'and' | 'all';
}
export interface RunPackageComponent {
  run_package_component: RunPackageComponentData;
}

export interface DatabaseSourceComponent {
  database_source_component: DatabaseSourceComponentData;
}

export interface CommonComponentData {
  id: string;
  alias: string;
  name: string;
  xy: XYCoords;
  dynamic_connection?: string;
  connection?: Partial<Connection>;
  cloud_storage_connection_id?: string;
  is_new?: boolean;
}

export interface TempComponent {
  component_type: Partial<AllComponentData>;
}

export type Component =
  | SelectComponent
  | SortComponent
  | RankComponent
  | LimitComponent
  | WindowComponent
  | SampleComponent
  | JoinComponent
  | CrossJoinComponent
  | CloneComponent
  | UnionComponent
  | FilterComponent
  | AssertComponent
  | AggregateComponent
  | CubeComponent
  | DistinctComponent
  | DatabaseSourceComponent
  | DatabaseDestinationComponent
  | CloudStorageSourceComponent
  | FacebookAdsInsightsSourceComponent
  | AnalyticsSourceComponent
  | Analytics4SourceComponent
  | RestApiSourceComponent
  | SalesforceSourceComponent
  | AdwordsSourceComponent
  | BingAdsSourceComponent
  | BigQuerySourceComponent
  | CloudStorageDestinationComponent
  | SalesforceDestinationComponent
  | AmazonRedshiftSourceComponent
  | AmazonRedshiftDestinationComponent
  | MongoSourceComponent
  | SpannerSourceComponent
  | NetsuiteSourceComponent
  | MongoDestinationComponent
  | SnowflakeDestinationComponent
  | BigQueryDestinationComponent
  | GoogleSpannerDestinationComponent
  | ExecuteSQLComponent
  | RunPackageComponent
  | NoteComponent
  | TempComponent
  | NetsuiteSOAPDestinationComponent
  | GoogleAdsDestinationComponent
  | FacebookDestinationComponent
  | TikTokDestinationComponent
  | HubspotDestinationComponent
  | RestApiDestinationComponent
  | FileMoverComponent
  | PdfToCsvComponent;
export type AllComponentData = SelectComponentData &
  SortComponentData &
  RankComponentData &
  LimitComponentData &
  WindowComponentData &
  SampleComponentData &
  JoinComponentData &
  CrossJoinComponentData &
  CloneComponentData &
  UnionComponentData &
  FilterComponentData &
  AssertComponentData &
  AggregateComponentData &
  CubeComponentData &
  DistinctComponentData &
  DatabaseSourceComponentData &
  DatabaseDestinationComponentData &
  CloudStorageSourceComponentData &
  FacebookAdsInsightsSourceComponentData &
  AnalyticsSourceComponentData &
  Analytics4SourceComponentData &
  RestApiSourceComponentData &
  SalesforceSourceComponentData &
  AdwordsSourceComponentData &
  BingAdsSourceComponentData &
  BigQuerySourceComponentData &
  CloudStorageDestinationComponentData &
  SalesforceDestinationComponentData &
  AmazonRedshiftSourceComponentData &
  AmazonRedshiftDestinationComponentData &
  MongoSourceComponentData &
  SpannerSourceComponentData &
  NetsuiteSourceComponentData &
  MongoDestinationComponentData &
  SnowflakeDestinationComponentData &
  BigQueryDestinationComponentData &
  GoogleSpannerDestinationComponentData &
  ExecuteSQLComponentData &
  RunPackageComponentData &
  NoteComponentData &
  NetsuiteSOAPDestinationComponentData &
  GoogleAdsDestinationComponentData &
  FacebookDestinationComponentData &
  TikTokDestinationComponentData &
  HubspotDestinationComponentData &
  RestApiDestinationComponentData &
  FileMoverComponentData &
  PdfToCsvComponentData;

export type AnyComponentData =
  | SelectComponentData
  | SortComponentData
  | RankComponentData
  | LimitComponentData
  | WindowComponentData
  | SampleComponentData
  | JoinComponentData
  | CrossJoinComponentData
  | CloneComponentData
  | UnionComponentData
  | FilterComponentData
  | AssertComponentData
  | AggregateComponentData
  | CubeComponentData
  | DistinctComponentData
  | DatabaseSourceComponentData
  | DatabaseDestinationComponentData
  | CloudStorageSourceComponentData
  | FacebookAdsInsightsSourceComponentData
  | AnalyticsSourceComponentData
  | RestApiSourceComponentData
  | SalesforceSourceComponentData
  | AdwordsSourceComponentData
  | BingAdsSourceComponentData
  | BigQuerySourceComponentData
  | CloudStorageDestinationComponentData
  | SalesforceDestinationComponentData
  | AmazonRedshiftSourceComponentData
  | AmazonRedshiftDestinationComponentData
  | MongoSourceComponentData
  | SpannerSourceComponentData
  | NetsuiteSourceComponentData
  | MongoDestinationComponentData
  | SnowflakeDestinationComponentData
  | BigQueryDestinationComponentData
  | GoogleSpannerDestinationComponentData
  | ExecuteSQLComponentData
  | RunPackageComponentData
  | NoteComponentData
  | NetsuiteSOAPDestinationComponentData
  | GoogleAdsDestinationComponentData
  | FacebookDestinationComponentData
  | TikTokDestinationComponentData
  | HubspotDestinationComponentData
  | RestApiDestinationComponentData
  | FileMoverComponentData
  | PdfToCsvComponentData;

export interface Edge {
  id: string;
  order: number;
  source: string;
  source_index: number;
  target: string;
  label?: string;
  event?: EdgeEvent;
}

export interface Position {
  x: number;
  y: number;
}

export enum EdgeEvent {
  success = 'success',
  failure = 'failure',
  completion = 'completion',
}

export interface ExtendedEdge extends Edge {
  sourcePosition: Position;
  sourceCurvePosition: Position;
  targetPosition: Position;
  targetCurvePosition: Position;
  togglePosition: Position;
  event?: EdgeEvent;
}

export type SchemaData = Array<boolean | string | number | string[]>;

export interface FieldDefinition {
  [key: string]: string;
}

export interface SchemaResponse {
  data?: SchemaData[];
  fields: FieldDefinition[];
  raw?: string[];
}

export interface ConnectionSchemaField {
  name: string;
  data_type?: string;
  alias?: string;
  isDuplicateError?: boolean;
  focusedProp?: string;
  disableSortable?: boolean;
  disableTypeEdit?: boolean;
  required?: boolean;
  predefined?: boolean;
  _category?: string;
  category?: string;
  editable?: boolean;
  markedToBeRemoved?: boolean;
  id?: string;
  shouldBeUnshifted?: boolean;
}
