import { Injectable } from '@angular/core';
import { Variables } from '../package.models';
import { parser } from '../../services/pig-parser';
import { ParserI } from '../../services/pig-parser.interface';
import { selectPackageId, selectSecretVariables, selectVariables } from '../store/package-designer.selectors';
import { Store } from '@ngrx/store';
import { combineLatest, switchMap } from 'rxjs';
import { first } from 'rxjs';
import { LabServicesResource } from '../../help/resources/lab-services.resource';
import { VariablesResource } from '../../account/resources/variables.resource';
import { ConnectionItemsResource } from 'src/app/connections/resources/connection-items.resource';

function hasSingleQuotes(str: string): boolean {
  return str.startsWith("'") && str.endsWith("'");
}

@Injectable({
  providedIn: 'root',
})
export class VariablesEvaluationService {
  variables$ = this.store.select(selectVariables);
  secretVariables$ = this.store.select(selectSecretVariables);

  constructor(
    private LabServices: LabServicesResource,
    private store: Store,
    private variablesResource: VariablesResource,
    private connectionItemsResource: ConnectionItemsResource,
  ) {}

  public interpolateVariablesInData(data: any) {
    this.connectionItemsResource.clearExpressions();

    return combineLatest([this.variables$, this.secretVariables$, this.variablesResource.getGlobalVariables()]).pipe(
      first(),
      switchMap(async ([variables, secretVariables, globalVariablesResponse]) =>
        this.interpolateVariables(
          data,
          { ...(variables || {}), ...((globalVariablesResponse || {}).global_variables || {}) },
          secretVariables,
        ),
      ),
    );
  }

  private async interpolateVariables(data: any, variables: Variables, secretVariables: Variables, nestedKey?: string) {
    if (typeof data === 'string' || !data || typeof data !== 'object') {
      return await Promise.resolve(data);
    }

    const result = {};

    for (const currKey of Object.keys(data)) {
      if (typeof data[currKey] === 'string') {
        const variableNames = Object.keys({ ...variables, ...secretVariables })
          .map((variableName) => `$${variableName}`)
          .filter((variable) => data[currKey].includes(variable));

        let currentValue = data[currKey];

        for (const variableName of variableNames) {
          const variableKey = variableName.replace('$', '');
          const isSecretVariable = !!secretVariables[variableKey];
          const isSecretVariableValue = isSecretVariable && !secretVariables[variableKey].includes('•'.repeat(5));

          if (!isSecretVariable || isSecretVariableValue) {
            if (isSecretVariableValue) {
              currentValue = currentValue.replace(variableName, secretVariables[variableKey]);
            } else if (hasSingleQuotes(variables[variableKey])) {
              currentValue = currentValue.replace(variableName, variables[variableKey].replace(/'/g, ''));
            } else {
              const newValue = this.interpolateNestedVariables(variables[variableKey], variables, secretVariables);
              const evaluatedValue = await this.evaluateExpression(
                newValue,
                variableKey,
                secretVariables,
                currKey,
                nestedKey,
              );
              currentValue = currentValue.replace(variableName, evaluatedValue);
            }
          }
        }

        result[currKey] = currentValue;
      } else if (typeof data[currKey] === 'object' && !Array.isArray(data[currKey])) {
        result[currKey] = await this.interpolateVariables(data[currKey], variables, secretVariables, currKey);
      } else if (Array.isArray(data[currKey])) {
        result[currKey] = await Promise.all(
          data[currKey].map((item) => this.interpolateVariables(item, variables, secretVariables, currKey)),
        );
      } else {
        result[currKey] = data[currKey];
      }
    }

    return result;
  }

  private interpolateNestedVariables(variableValue: string, variables: Variables, secretVariables: Variables) {
    Object.keys(variables)
      .map((variableName) => `$${variableName}`)
      .filter((variable) => variableValue.includes(variable))
      .forEach((variableName) => {
        const variableKey = variableName.replace('$', '');
        const isSecretVariable = secretVariables[variableKey];

        if (!isSecretVariable) {
          if (hasSingleQuotes(variables[variableKey])) {
            variableValue = variableValue.replace(variableName, variables[variableKey]);
          } else {
            const newValue = this.interpolateNestedVariables(variables[variableKey], variables, secretVariables);
            variableValue = variableValue.replace(variableName, newValue);
          }
        }
      });

    return variableValue;
  }

  async evaluateExpression(
    expression: string,
    variableKey: string,
    secretVariables: Variables,
    currKey: string,
    nestedKey?: string,
  ) {
    try {
      const hasExpressionSecretVariable = Object.keys(secretVariables)
        .map((variableName) => `$${variableName}`)
        .filter((variable) => expression.includes(variable));

      const parserResult = (parser as ParserI).parse(expression);
      let result;
      let error;

      if (hasExpressionSecretVariable.length > 0) {
        this.connectionItemsResource.evaluateInAPI(parserResult, currKey, expression, nestedKey);
        result = expression;
      } else {
        const response = await this.LabServices.evaluate(parserResult);
        result = response.result;
        error = response.error;
      }

      if (error) {
        throw new Error(typeof error === 'string' ? error : 'Network error');
      }

      return result;
    } catch (error) {
      let errorMessage = error instanceof Error ? error.message : '';

      if (errorMessage.includes('Parse error')) {
        errorMessage = `Parsing error. Please verify that you are using the correct function, including proper casing, data types, and parameters.`;
      }

      errorMessage = errorMessage.split('\n').join('<br>');
      throw `<strong>Error:</strong> The variable <strong>$${variableKey}</strong> encountered an error during evaluation.<br> <strong>Details:</strong> ${errorMessage}`;
    }
  }
}
